<template>
    <div
        id="config-payment"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit Payment Config Payment</h5>
                </div>
                <form @submit.prevent="submitData()">
                    <div class="modal-body">
                         <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >Key*:</label
                            >
                            <div class="col-7">
                                <label for="" class="font-weight-bold">{{config.key }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label
                                for="example-text-input"
                                class="col-4 col-form-label"
                                >Value:</label
                            >
                            <div class="col-7">
                                <input
                                    type="text"
                                    class="form-control"
                                    v-model="config.value"
                                
                                />
                            </div>
                        </div>
                        
                    </div>
                    <div class="modal-footer">
                        <button
                            type="button"
                            class="btn btn-clean btn-bold btn-upper btn-font-md"
                            @click="clearData()"
                        >
                            {{ $t("button.close") }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-default btn-bold btn-upper btn-font-md"
                        >
                            {{ $t("button.save") }}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import SweetAlertTemplate from "../../../components/_general/SweetAlert";
import BlockUI from "../../../components/_js/BlockUI";
import {mapActions} from 'vuex'
const blockUI = new BlockUI();
const SweetAlert = Vue.extend(SweetAlertTemplate);


export default {
    components: {
    },
    data() {
        return {
           config: {
               key: '',
               value: ''
           }
        };
    },
    mounted() {

        if(this.modalData) {
           this.config = JSON.parse(JSON.stringify(this.modalData))
        }
        $("#config-payment").modal();
    },
    props: {
        modalData: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        clearData: function() {
            $("#config-payment").modal("hide");
            this.$emit("update:showModal", false);
        },
        ...mapActions({
            setPaymentConfigGlobal: 'PaymentStore/SET_CONFIG_PAYMENT_GLOBAL'
        }),
        submitData: function() {
            var vx = this;
            const params = {
                ...(this.config.id ? {id: this.config.id} : {}),
                key: this.config.key,
                value: this.config.value
            }
            new SweetAlert().showConfirm({
                            swalTitle: "Apakah Anda yakin?",
                            swalText: "Perubahan data Payment Global akan tersimpan.",
                            swalType: "info",
                            onConfirmButton: function() {
                                blockUI.blockModal();
                                vx.create(params);
                            },
                            onCancelButton: function() {
                                blockUI.unblockModal();
                            }
            })
        },
        create(payload) {
            this.setPaymentConfigGlobal(payload)
            blockUI.unblockModal();
            this.clearData()
        },
    },
};
</script>
